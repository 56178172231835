<template>
  <b-card
    :key="key"
    :header="$t('fCustomer_crdNotes_title')"
    header-tag="h4"
    class="text-center"
  >
    <h1 class="card-title pricing-card-title">
      <span>{{ notes && notes.tot }}</span>
      <small class="text-muted">{{ $t('fCustomer_crdNotes_notes') }}</small>
    </h1>
    <ul class="list-unstyled mt-3 mb-3 text-muted">
      <li>{{ notes && notes.received }} {{ $t('fCustomer_crdNotes_received') }}</li>
      <li>{{ notes && notes.left }} {{ $t('fCustomer_crdNotes_sent') }}</li>
    </ul>
    <b-btn
      variant="primary"
      class="mt-auto"
      size="sm"
      block
      @click="$emit('on-show', itemId)"
    >
      {{ $t('fCustomer_crdNotes_manage') }}
    </b-btn>
  </b-card>
</template>

<script>
import { mapComputedFormFields, mapComputedStateFields } from '~/mixins/globalForm'

export default {
  name: 'CardNotes',
  props: {
    itemId: {
      type: [Number, String],
      required: true
    },
    isLocked: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapComputedFormFields('customerEdit', [
      'bonusCredits',
      'cashout',
      'addCredits'
    ]),
    ...mapComputedStateFields('customerEdit', [
      'dirty',
      'type',
      'editColumnName',
      'editColumnCursor',
      'info'
    ]),
    notes () {
      return this.info && this.info.notes
    }
  },
  watch: {
    form: {
      handler () {
        this.$forceUpdate()
      },
      deep: true
    },
    info: {
      handler () {
        this.$forceUpdate()
      },
      deep: true
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>
