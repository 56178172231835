<template>
  <b-card
    :key="key"
    :header="$t('fCustomer_crdWallet_title')"
    header-tag="h4"
    class="text-center"
  >
    <h1 class="card-title pricing-card-title">
      <span>{{ wallet && wallet.amount }}</span>
      <small class="text-muted">{{ $t('fCustomer_crdWallet_credits') }}</small>
    </h1>
    <ul class="list-unstyled mt-3 mb-3 text-muted">
      <li>{{ wallet && wallet.purchased }} {{ $t('fCustomer_crdWallet_purchased') }}</li>
      <li>{{ wallet && wallet.bonus }} {{ $t('fCustomer_crdWallet_bonus') }}</li>
    </ul>
    <div v-if="sex === 'M'">
      <b-form-group
        v-show="bonusesState && !isLocked"
        id="manage-bonuses"
        :label="$t('fCustomer_crdWallet_manageBonuses')"
        label-for="form-input-manage-bonuses"
        class="pt-2 pb-2"
      >
        <b-input-group>
          <b-form-input
            id="form-input-manage-bonuses"
            ref="bonusCredits"
            v-model="bonusCredits"
            type="number"
            size="sm"
            @input="onChange('bonusCredits')"
          />
          <b-input-group-append>
            <b-button
              :variant="bonusCredits >= 0 ? 'outline-success' : 'outline-warning'"
              :disabled="parseInt(bonusCredits) === 0"
              size="sm"
              @click="onManageBonuses"
            >
              <font-awesome-icon :icon="['fas', bonusCredits >= 0 ? 'plus' : 'minus']" />
            </b-button>
            <b-button
              :variant="'outline-danger'"
              size="sm"
              @click="toggleBonusesForm"
            >
              <font-awesome-icon :icon="['fas', 'xmark']" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-btn
        v-if="!bonusesState"
        variant="primary"
        size="sm"
        class="mb-2"
        :disabled="isLocked"
        @click="toggleBonusesForm"
      >
        {{ $t('fCustomer_crdWallet_manageBonuses') }}
      </b-btn>
    </div>
    <div v-else>
      <b-form-group
        v-if="cashoutState && !isLocked"
        id="manage-bonuses"
        :label="$t('fCustomer_crdWallet_makeCashout')"
        label-for="form-input-manage-bonuses"
        class="pt-2 pb-2"
      >
        <b-input-group>
          <b-form-input
            id="form-input-manage-bonuses"
            ref="cashout"
            v-model="cashout"
            type="number"
            size="sm"
            @input="onChange('cashout')"
          />
          <b-input-group-append>
            <b-button
              :variant="cashout >= 0 ? 'outline-success' : 'outline-warning'"
              :disabled="parseInt(cashout) === 0"
              size="sm"
              @click="onManageCashout"
            >
              <font-awesome-icon :icon="['fas', cashout >= 0 ? 'plus' : 'minus']" />
            </b-button>
            <b-button
              :variant="'outline-danger'"
              size="sm"
              @click="toggleCashoutForm"
            >
              <font-awesome-icon :icon="['fas', 'xmark']" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-btn
        v-else
        variant="primary"
        size="sm"
        class="mb-2"
        :disabled="isLocked"
        @click="toggleCashoutForm"
      >
        {{ $t('fCustomer_crdWallet_makeCashout') }}
      </b-btn>
      <b-form-group
        v-if="creditsState && !isLocked"
        id="manage-bonuses"
        :label="$t('fCustomer_crdWallet_addCredits')"
        label-for="form-input-manage-bonuses"
        class="pt-2 pb-2"
      >
        <b-input-group>
          <b-form-input
            id="form-input-manage-bonuses"
            ref="addCredits"
            v-model="addCredits"
            type="number"
            size="sm"
            @input="onChange('addCredits')"
          />
          <b-input-group-append>
            <b-button
              :variant="addCredits >= 0 ? 'outline-success' : 'outline-warning'"
              :disabled="parseInt(addCredits) === 0"
              size="sm"
              @click="onManageCredits"
            >
              <font-awesome-icon :icon="['fas', addCredits >= 0 ? 'plus' : 'minus']" />
            </b-button>
            <b-button
              :variant="'outline-danger'"
              size="sm"
              @click="toggleCreditsForm"
            >
              <font-awesome-icon :icon="['fas', 'xmark']" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-btn
        v-else
        variant="primary"
        size="sm"
        class="mb-2"
        :disabled="isLocked"
        @click="toggleCreditsForm"
      >
        {{ $t('fCustomer_crdWallet_addCredits') }}
      </b-btn>
    </div>
  </b-card>
</template>

<script>
import { mapComputedFormFields, mapComputedStateFields } from '~/mixins/globalForm'

export default {
  name: 'CardWallets',
  props: {
    itemId: {
      type: [Number, String],
      required: true
    },
    // info: {
    //   type: Object,
    //   default: () => ({})
    // },
    isLocked: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      bonusesState: false,
      cashoutState: false,
      creditsState: false
    }
  },
  computed: {
    ...mapComputedFormFields('customerEdit', [
      'bonusCredits',
      'cashout',
      'addCredits'
    ]),
    ...mapComputedStateFields('customerEdit', [
      'dirty',
      'type',
      'editColumnName',
      'editColumnCursor',
      'info'
    ]),
    sex () {
      return this.info && this.info.profile && this.info.profile.sex
    },
    wallet () {
      return this.info && this.info.wallet
    },
    awailableCashout () {
      return this.wallet
        ? this.wallet.amount
        : 0
    }
  },
  watch: {
    form: {
      handler () {
        this.$forceUpdate()
      },
      deep: true
    },
    info: {
      handler () {
        this.$forceUpdate()
      },
      deep: true
    }
  },
  methods: {
    setInputState () {
      const cols = [
        'bonusCredits',
        'cashout',
        'addCredits'
      ]
      if (this.editColumnName === 'bonusCredits') {
        this.bonusesState = true
        this.$forceUpdate()
      }
      this.$nextTick(() => {
        if (cols.includes(this.editColumnName) && this.$refs[this.editColumnName]) {
          this.$refs[this.editColumnName].$el.focus()
          if (this.$refs[this.editColumnName].$el.setSelectionRange && this.editColumnCursor >= 0) {
            this.$refs[this.editColumnName].$el.setSelectionRange(this.editColumnCursor, this.editColumnCursor)
          }
        }
      })
    },
    onChange (colName) {
      this.editColumnName = colName
      if (this.$refs[colName]) {
        const column = this.$refs[colName]
        this.editColumnCursor = (column && column.$el.selectionStart) || -1
      }
      this.$nextTick(() => {
        this.setInputState()
      })
    },
    isDirty (fieldKey) {
      const dirtyFields = this.dirty && Object.keys(this.dirty)
      return !!(dirtyFields && dirtyFields.length && dirtyFields.includes(fieldKey))
    },
    toggleBonusesForm () {
      this.bonusesState = !this.bonusesState
      // this.cashout = 0
    },
    toggleCashoutForm () {
      this.cashoutState = !this.cashoutState
      this.cashout = this.cashoutState ? this.awailableCashout * -1 : 0
    },
    toggleCreditsForm () {
      this.creditsState = !this.creditsState
      this.cashout = 0
    },
    onManageBonuses () {
      this.bonusesState = false
      this.$emit('save')
    },
    onManageCashout () {
      this.cashoutState = false
      this.$emit('save')
    },
    onManageCredits () {
      this.creditsState = false
      this.$emit('save')
    }
  }
}
</script>

<style scoped>

</style>
