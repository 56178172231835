<template>
  <b-card
    :key="key"
    :header="$t('fCustomer_crdSms_title')"
    header-tag="h4"
    class="text-center"
  >
    <div class="d-flex flex-column align-items-center justify-content-around h-100">
      <h1 class="card-title pricing-card-title">
        <span>{{ smsHistoryTotal }}</span>
        <small class="text-muted">
          {{ $t('fCustomer_crdSms_count') }}
        </small>
      </h1>
      <b-btn
        variant="success"
        class="mt-auto"
        size="sm"
        block
        @click="$emit('on-new', itemId)"
      >
        {{ $t('fCustomer_crdSms_new') }}
      </b-btn>
      <b-btn
        variant="primary"
        class="mt-auto"
        size="sm"
        block
        @click="$emit('on-show', itemId)"
      >
        {{ $t('fCustomer_crdSms_manage') }}
      </b-btn>
    </div>
  </b-card>
</template>

<script>
import { mapComputedStateFields } from '~/mixins/globalForm'

export default {
  name: 'CardSms',
  props: {
    itemId: {
      type: [Number, String],
      required: true
    },
    isLocked: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapComputedStateFields('customerEdit', [
      'info'
    ]),
    smsHistoryTotal () {
      return this.info?.sms?.count || 0
    }
  },
  watch: {
    form: {
      handler () {
        this.$forceUpdate()
      },
      deep: true
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>
