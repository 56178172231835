<template>
  <b-card
    v-if="info.profile && info.profile.selfie_path"
    :key="key"
    :header="$t('fCustomer_crdSelfie_title')"
    header-tag="h4"
    class="text-center"
  >
    <div class="d-flex flex-column align-items-center justify-content-around h-100">
      <b-img
        :src="info.profile.selfie_path"
        :fluid="true"
        @click="lightBoxIndex = 0"
      />

      <light-box
        :items="lightBoxItems"
        :index="lightBoxIndex"
        :use-zoom-bar="true"
        :full-screen="true"
        @close="lightBoxIndex = null"
      />
    </div>
  </b-card>
</template>

<script>
import { mapComputedStateFields } from '~/mixins/globalForm'

export default {
  name: 'CardSelfie',
  props: {
    itemId: {
      type: [Number, String],
      required: true
    },
    isLocked: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      lightBoxIndex: null
    }
  },
  computed: {
    ...mapComputedStateFields('customerEdit', [
      'info'
    ]),
    smsHistoryTotal () {
      return this.info?.sms?.count || 0
    },
    lightBoxItems () {
      return [
        {
          title: this.$t('fCustomer_crdSelfie_label'),
          description: `<a target="_blank" href="${this.info.profile.selfie_path}">${this.info.profile.selfie_path}</a>`,
          src: this.info.profile.selfie_path
        }
      ]
    }
  },
  watch: {
    form: {
      handler () {
        this.$forceUpdate()
      },
      deep: true
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>
