<template>
  <b-card
    :header="$t('fCustomer_crdPaid_title')"
    header-tag="h4"
    class="text-center"
  >
    <h1 class="card-title pricing-card-title">
      <span> {{ amount }}</span>
      <small class="text-muted">€</small>
    </h1>
    <ul class="list-unstyled mt-3 mb-3 text-muted">
      <li>
        {{ count }} {{ $t('fCustomer_crdPaid_purchases') }}
        <span v-if="false && sex === 'M'">
          ({{ level }})
        </span>
      </li>
    </ul>
    <b-btn
      variant="primary"
      class="mt-auto"
      size="sm"
      block
      @click="$emit('on-show', itemId)"
    >
      {{ $t('fCustomer_crdPaid_viewPayments') }}
    </b-btn>
  </b-card>
</template>

<script>
import { mapComputedStateFields } from '~/mixins/globalForm'
export default {
  name: 'CardSpent',
  props: {
    itemId: {
      type: [Number, String],
      required: true
    }
  },
  computed: {
    ...mapComputedStateFields('customerEdit', [
      'info'
    ]),
    amount () {
      return this.info && this.info.spent && this.info.spent.amount
    },
    count () {
      return this.info && this.info.spent && this.info.spent.count
    },
    sex () {
      return this.info && this.info.profile && this.info.profile.sex
    },
    level () {
      return this.info && this.info.profile && this.info.profile.level
    }
  }
}
</script>

<style scoped>

</style>
